import React, { useState } from "react";

import {
  Col,
  Container,
  Image,
  Row,
  Button,
  Form,
  Modal,
  Alert,
} from "react-bootstrap";
import Countdown from "react-countdown";
import { style } from "./Home.styles";
import Footer from "../Footer";
import Web3 from "web3";
import ReactTooltip from "react-tooltip";
import abi from "../../contracts/abi.json";
import { AbiItem } from "web3-utils";
// Images
import skull from "../img/SkullSolidTransparent.png";
import boardRoom from "../img/resistancepromo1.png";
import spin from "../img/loadingAnim.gif";
import wait from "../img/Spin-1s-200px.svg";
import bounce from "../img/checkIcon-256px.gif";
import bounce_stat from "../img/check_static.png";
import looksrare from "../img/looksrare.svg";
import opensea from "../img/opensea.svg";
import rarible from "../img/Rarible.svg";
import infobadge from "../img/info-badge.svg";
import a1 from "../img/1386.png";
import a2 from "../img/1359.png";
import a3 from "../img/1458.png";
import a4 from "../img/14.png";
import { useRebelContract } from "../../hooks/useContract";
type Props = {
  currentAccount?: string;
};

const Home: React.FC<Props> = ({ currentAccount }) => {
  const [mintQ, setMintQ] = useState<number>(1);
  const { ethereum } = window;
  const [address, setAddress] = useState<string | undefined>(currentAccount);
  const [transactionStage, setTransactionStage] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [transferModalShow, setTransferModalShow] = useState(false);
  const [tokenContract, setTokenContract] = useState<string>(
    process.env.REACT_APP_GOERLI_CONTRACT_ADDRESS!!
  );
  const [checkImageSource, setCheckImageSource] = useState<string>(bounce);
  const handleMintQChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMintQ(parseInt(event.target.value) || 0);
  };
  const eligibilityStyle = (el: boolean) => {
    return el ? { color: "#66c844" } : { color: "gray" };
  };
  const marketplaces = () => {
    return (
      <>
        <a
          className='link-a'
          href='https://opensea.io/collection/boardwalkresistance'
        >
          <Image className='link-images-bg' src={opensea} />
        </a>
        &nbsp;&nbsp;
        <a
          className='link-a'
          href='https://looksrare.org/collections/0xb311035B54Ba5a174f732548Df19Dc846816dE60'
        >
          <Image className='link-images-bg' src={looksrare} />
        </a>
        <a
          className='link-a'
          href='https://rarible.com/boardwalkresistance/items'
        >
          <Image className='link-images-bg' src={rarible} />
        </a>
      </>
    );
  };
  const handleMintButtonClick = async () => {
    if (mintQ > 0) {
      try {
        let provider = window.ethereum;
        const web3 = new Web3(provider);
        const con = new web3.eth.Contract(abi as AbiItem[], tokenContract);

        setTransferModalShow(true);
        setTransactionStage("1");
        setTransactionHash("Please confirm the transaction!");

        con.methods
          .mint(mintQ)
          .send({
            value: mintQ * uPrice,
            from: ethereum.selectedAddress,
          })
          .on("transactionHash", function (h: string) {
            if (!transferModalShow) {
              setTransferModalShow(true);
            }
            setTransactionStage("2");

            setTransactionHash(h);
          })
          .on("receipt", function (h: string) {
            if (!transferModalShow) {
              setTransferModalShow(true);
            }
            setTransactionStage("3");

            setCheckImageSource(bounce);
            setTimeout(function () {
              setCheckImageSource(bounce_stat);
            }, 750);
          })
          .on("error", function (er: any) {
            if (!transferModalShow) {
              setTransferModalShow(true);
            }
            setTransactionStage("4");
            setTransactionHash(er.message);
          })
          .catch((e: any) => {
            console.log(e.message);
          });
      } catch (e: any) {
        console.log(e.message);
      }
    }
  };
  const txNetSwitch = () => {
    switch (network) {
      case 1:
        return "";
      case 5:
        return "goerli.";
      case 3:
        return "ropsten.";
      default:
        return "";
    }
  };
  const renderMintStatus = () => {
    if (window.ethereum != undefined) {
      switch (mintStage) {
        case "0":
          return (
            <>
              <h4>Hold your Horses.</h4>
              <p>
                <strong>The sale hasn't started yet.</strong>
              </p>
              <p>
                Join us in <Countdown date={saleDate} /> for the Sale
              </p>
            </>
          );
        case "1":
          return (
            <>
              <h4>Welcome to Early Access.</h4>
              <p>
                <strong>
                  {earlyAccessClaimed} of 1600 Recruited in Early Access.
                </strong>
              </p>
              <p>Your Price Per Rebel: 0 Ξ</p>
              <div className='input-group mb-4'>
                <input
                  type='number'
                  max={
                    1600 - earlyAccessClaimed >= 5
                      ? 5 - earlyAccessBalance
                      : 1600 - earlyAccessClaimed
                  }
                  className='form-control'
                  placeholder='1'
                  style={{ minWidth: "12%" }}
                  value={mintQ}
                  onChange={handleMintQChange}
                  aria-label='Mint Quantity'
                  aria-describedby='basic-addon2'
                ></input>
                <div className='input-group-append'>
                  <button
                    className='btn mint-button'
                    data-tip={"Mint the Current Quantity (" + mintQ + ")"}
                    type='button'
                    onClick={async () => {
                      if (mintQ > 0) {
                        try {
                          let provider = window.ethereum;
                          const web3 = new Web3(provider);
                          const con = new web3.eth.Contract(
                            abi as AbiItem[],
                            tokenContract
                          );

                          setTransferModalShow(true);
                          setTransactionStage("1");
                          setTransactionHash("Please confirm the transaction!");

                          con.methods
                            .earlyAccessMint(mintQ)
                            .send({
                              from: ethereum.selectedAddress,
                            })
                            .on("transactionHash", function (h: string) {
                              setTransactionStage("2");

                              setTransactionHash(h);
                            })
                            .on("receipt", function (h: string) {
                              if (!transferModalShow) {
                                setTransferModalShow(true);
                              }
                              setTransactionStage("3");

                              setCheckImageSource(bounce);
                              setTimeout(function () {
                                setCheckImageSource(bounce_stat);
                              }, 750);
                            })
                            .on("error", function (er: any) {
                              setTransactionStage("4");
                              setTransactionHash(er.message);
                            })
                            .catch((e: any) => {
                              console.log(e.message);
                            });
                        } catch (e: any) {
                          console.log(e.message);
                        }
                      }
                    }}
                  >
                    Mint
                  </button>{" "}
                  <button
                    data-tip={
                      "Set Mint Quantity to the Maximum allowed for your wallet (" +
                      (1600 - earlyAccessClaimed >= 2
                        ? 5 - earlyAccessBalance
                        : 1600 - earlyAccessClaimed) +
                      ")"
                    }
                    className='btn btn-small mint-button'
                    onClick={() => {
                      /* @ts-ignore */
                      if (mintStage === "1") {
                        setMintQ(5 - earlyAccessBalance);
                      } else {
                        setMintQ(
                          1600 - earlyAccessClaimed >= 2
                            ? 5 - earlyAccessBalance
                            : 1600 - earlyAccessClaimed
                        );
                      }
                    }}
                  >
                    Set Max{" ("}
                    {1600 - earlyAccessClaimed >= 2
                      ? 5 - earlyAccessBalance
                      : 1600 - earlyAccessClaimed}
                    {")"}
                  </button>
                  <ReactTooltip />
                </div>
              </div>
              <p className='fine--print'>Mint Total: 0 Ξ + Network Gas Fee</p>
            </>
          );
        case "eaClaimed":
          return (
            <>
              <h4>Early Access has Closed!</h4>
              <p>
                <strong>
                  {earlyAccessClaimed} of 1200 Recruited in Early Access.
                </strong>
              </p>
              <p>
                The early rising rebels have all been recruited. Please join us
                in <Countdown date={saleDate} />{" "}
              </p>
            </>
          );
        case "eaMinted":
          return (
            <>
              <h4>Thank you for Participating in Early Access.</h4>
              <p>
                Join us in <Countdown date={saleDate} /> <br />
                for the Public Sale
              </p>
            </>
          );
        case "maxClaimed":
          return (
            <>
              <h4>Thank you for Joining the Resistance.</h4>
              <p>You have claimed the maximum number of Rebels.</p>
              <p className='fine--print'>
                Want More? Check out the aftermarkets
              </p>
              {marketplaces()}
            </>
          );
        case "2":
          return (
            <>
              <h4>Join the Resistance.</h4>
              <p>
                <strong>
                  {supply} of {mSupply} Recruited; Join the Resistance!
                </strong>
              </p>
              <p>
                Your Price Per Rebel: {Web3.utils.fromWei(uPrice.toString())} Ξ
              </p>
              <div className='input-group mb-3'>
                <input
                  type='number'
                  max={
                    mSupply - supply >= 10 - (balance - earlyAccessBalance)
                      ? 10 - (balance - earlyAccessBalance)
                      : mSupply - supply
                  }
                  className='form-control'
                  placeholder='1'
                  width='25%'
                  value={mintQ}
                  onChange={handleMintQChange}
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                ></input>
                <div className='input-group-append'>
                  <button
                    className='btn mint-button'
                    data-tip={"Mint the Current Quantity (" + mintQ + ")"}
                    type='button'
                    onClick={async () => {
                      handleMintButtonClick();
                    }}
                  >
                    Mint
                  </button>{" "}
                  <button
                    data-tip={
                      "Set Mint Quantity to the Maximum allowed for your wallet (" +
                      (mSupply - supply >= 10 - (balance - earlyAccessBalance)
                        ? 10 - (balance - earlyAccessBalance)
                        : mSupply - supply) +
                      ")"
                    }
                    className='btn btn-small mint-button'
                    onClick={() => {
                      /* @ts-ignore */
                      if (mintStage === "1") {
                        setMintQ(5 - earlyAccessBalance);
                      } else {
                        setMintQ(
                          mSupply - supply >=
                            10 - (balance - earlyAccessBalance)
                            ? 10 - (balance - earlyAccessBalance)
                            : mSupply - supply
                        );
                      }
                    }}
                  >
                    Set Max{" ("}
                    {mSupply - supply >= 10 - (balance - earlyAccessBalance)
                      ? 10 - (balance - earlyAccessBalance)
                      : mSupply - supply}
                    {")"}
                  </button>
                </div>
              </div>
              <p className='fine--print'>
                Mint Total: {Web3.utils.fromWei((mintQ * uPrice).toString())} Ξ
                + Network Gas Fee
              </p>

              <ReactTooltip delayShow={1000} />
            </>
          );
        case "3":
          return (
            <>
              <h4>The Rebels are sold out.</h4>
              <p>
                If you'd like to buy an Rebel check out the secondary market:
                <br />
              </p>
              {marketplaces()}
            </>
          );
      }
    } else {
      return (
        <>
          <h4>Something's Wrong.</h4>
          <p>
            <strong>We can't detect a Web3 Provider on your Browser :(</strong>
          </p>
          <br></br>
          <p>
            You'll need a Web3 Enabled browser to interact with the site. It
            makes all the cool Blockchain stuff work!
            <br></br>
            <br></br>
            <p className='fine--print'>
              Please install or connect a Wallet or Web3 Provider to continue.
              Or you can purchase a Rebel on the secondary market:
            </p>
            <br></br>
            {marketplaces()}
          </p>
        </>
      );
    }
  };

  const renderSwitch = (param: string, transactionMessage: string) => {
    switch (param) {
      case "0":
        return <></>;
      case "1":
        return (
          <>
            <div>
              <Image src={wait} />
              <p>{transactionHash}</p>
            </div>
          </>
        );
      case "2":
        return (
          <>
            <div>
              <Image width='200px' src={spin} />
              <p>Your transaction has been submitted!</p>
              <p>
                <a
                  href={
                    "https://" +
                    txNetSwitch() +
                    "etherscan.io/tx/" +
                    transactionMessage
                  }
                >
                  View Transaction on Etherscan
                </a>
                <br />
              </p>
              <p className='text-small'>
                It may take a moment for the transaction to appear on Etherscan
              </p>
            </div>
          </>
        );
      case "3":
        setTimeout(function () {
          setCheckImageSource(bounce_stat);
        }, 600);
        return (
          <>
            <Image width='128px' id='check' src={checkImageSource} />
            <br />
            <br />
            <p>
              Transaction Complete: Mint Successful!
              <br />
              <a
                className='modal-links'
                href={"https://boardwalkresistance.com/my-rebels/"}
              >
                Head over to "My Rebels" to view your Rebels!
              </a>
            </p>
            <p className='text-small'>
              <a
                href={
                  "https://" +
                  txNetSwitch() +
                  "etherscan.io/tx/" +
                  transactionMessage
                }
              >
                View Transaction on Etherscan
              </a>
            </p>
          </>
        );
      case "4":
        return (
          <div>
            <i
              style={{ color: "white", fontSize: "64px" }}
              className='fa-light fa-exclamation-circle'
            ></i>
            <br />
            <p>
              Oh geez, oh man...
              <br />
              {transactionHash}
            </p>
          </div>
        );
      default:
        return (
          <div>
            <i
              font-size='20px'
              className={"fa-regular fa-circle-exclamation"}
            ></i>
            <p>
              Oh geez, oh man...
              <br />
              {transactionHash}
            </p>
          </div>
        );
    }
  };
  /* @ts-ignore */

  const connectWallet = async () => {
    if (!ethereum) {
      console.log("No wallet plugin is available!");
      return;
    } else {
      try {
        const [account] = await ethereum.request({
          method: "eth_requestAccounts",
        });
        // @ts-ignore
        ethereum.on("accountsChanged", ([newAccount]) => {
          setAddress(newAccount);
        });
        ethereum.on("chainChanged", (_chainId: any) => {
          window.location.reload();
        });
        setAddress(account);
      } catch (err) {
        console.log(err);
      }
    }
  };
  /*@ts-ignore*/
  const {
    saleTime,
    supply,
    mSupply,
    uPrice,
    balance,
    network,
    earlyAccessBalance,
    mintStage,
    earlyAccessClaimed,
    earlyAccessEligible,
    controlCenterBalance,
  } = useRebelContract(address);
  const saleDate = new Date(Date.now() + saleTime * 1000);
  connectWallet().then(() => {});
  return (
    <div className='home-content'>
      {style}
      <Container className='g-0 padded-container'>
        <Row className='align-items-top text-center'>
          {window.ethereum == undefined ? (
            <Alert>
              We could not detect a Web3 Provider on this browser. The site will
              not function properly without a Web3 service like MetaMask
            </Alert>
          ) : (
            <>
              {network !== 1 ? (
                <>
                  <Alert>
                    Incorrect Network Detected please connect to the Ethereum
                    Main Network.
                  </Alert>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          <Col className='home-content' lg={12} md={12}>
            <Image className='hero-image' src={boardRoom} />
          </Col>
        </Row>
        <Row className='align-items-center'>
          <Col className='home-content' lg={8} md={8} sm={12}>
            <br />
            <h4>
              <strong>
                WELCOME TO THE <br></br>RESISTANCE.
              </strong>
            </h4>
            <hr />
            <p>
              The Boardwalk Resistance is a collection of 3,333 hand drawn,
              freedom fighting, Rebelious Penguin NFTs. Molded by the tyranical
              rule of the Board Ape Collective, each Rebel lives on the Ethereum
              Blockchain as a unique digital collectible. Owning a Rebel
              provides access to an ongoing story within the Boardwalk
              Collective Universe. Additionally, each Rebel serves as a
              membership pass for any NFTs created by our Artist.
            </p>
          </Col>
          <Col className='home-content image-grid' lg={4} md={4} sm={12}>
            <br />
            <Row>
              <Image className='grid-image' src={a3} />
              <Image className='grid-image' src={a2} />
            </Row>
            <Row>
              <Image className='grid-image' src={a1} />
              <Image className='grid-image' src={a4} />
            </Row>
          </Col>
        </Row>
        <hr id='join' />
        <Row className='align-items-center'>
          <Col className='home-content' lg={12} md={12} sm={12}>
            <br />
            <h4>
              <strong>
                GET A REBEL
                <br /> JOIN THE RESISTANCE.
              </strong>
            </h4>
            <hr />
            <p>
              The Boardwalk Resistance Mint will occur on April 28th-29th, 2023
              and will be divided into 2 Stages: <strong>Member Mint</strong>,
              and <strong>Public Mint</strong>. Check below for more info!
            </p>
            <ul>
              <li style={{ listStyleType: "none" }}>
                <strong style={eligibilityStyle(controlCenterBalance > 0)}>
                  {controlCenterBalance > 0 ? (
                    <i className='fa-solid fa-circle-check'></i>
                  ) : (
                    <i className='fa-solid fa-circle-xmark'></i>
                  )}{" "}
                  Member Mint (28 April 2023 10:00 PM EST/ (29 April 2023) 2:00
                  AM UTC)
                </strong>
                <p className='fine--print'>
                  For Holders of NFTs made by our Artist. The Member Price per
                  Rebel is <strong>0 Ξ + Network Gas</strong>
                </p>
              </li>
              <li style={{ listStyleType: "none" }}>
                <strong style={{ color: "#66c844" }}>
                  <i className='fa-solid fa-circle-check'></i> Public Mint (1:00
                  PM EST/5:00 PM UTC)
                </strong>
                <p className='fine--print'>
                  Open to Everyone! The Public price per Rebel is{" "}
                  <strong>0.05 Ξ + Network Gas</strong>
                </p>
              </li>
            </ul>
            <p className='fine--print'>
              <strong>
                Wondering if you're eligible for one of the above stages?
              </strong>{" "}
              If a stage has a{" "}
              <i
                style={{ color: "#66c844" }}
                className='fa-solid fa-circle-check'
              ></i>{" "}
              and <strong style={{ color: "#66c844" }}>green text</strong>, that
              means our smart contract has found you eleigible for the stage and
              you may participate!
            </p>
            <br />
          </Col>
        </Row>
        <Row className='gx-0 gy-0 g-0 align-items-center mint-panel'>
          <Col lg={4} md={4} sm={12}>
            <h2>
              <strong>
                JOIN THE
                <br />
                BOARDWALK RESISTANCE.
              </strong>
            </h2>
          </Col>
          <Col className='center-padded' lg={6} md={6} sm={12}>
            <>{renderMintStatus()}</>
          </Col>
        </Row>
        <hr />
        <Row className='align-items-center' id='whatmakesanape'>
          <Col className='home-content' lg={7} md={7} sm={12}>
            <br />
            <h4>
              <strong>
                WHAT MAKES A REBEL
                <br />A BOARDWALK RESISTANCE REBEL?
              </strong>
            </h4>
            <hr />
            <p>
              The Boardwalk Resistance consists of 3,333 Programmatically
              generated Penguins, each fighting the tyrannical rule of the Board
              Ape Collective. <br></br>
              <br></br>
              Each Rebel is unique; some choose to employ stealth donning
              various disguises, others taking a less subtle approach. Each is
              equipped with hand drawn accessories that will certainly insure
              their victory over the BAC. Several stories and differing
              motivations are revealed throughout the collection, and each is
              made up of a carefully-crafted and highly tuned Rebel generating
              machine.
              <br />
              <br />
              Each Rebel is stored as an ERC-721 token on the Ethereum
              Blockchain. Each Rebel's image and metadata is hosted,
              permanently, on Arweave. Each Rebel costs 0.05 or 0 (for Members)
              with an early access free mint for Board Ape Collective members
              (that went so terribly, I adjusted the price).{" "}
            </p>
          </Col>

          <Col className='home-content ' lg={5} md={5} sm={12}>
            <Row className='text-center jc-center'>
              <Image className='ape-image' src={a4} />
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className='align-items-center' id='whatmakesanape'>
          <Col className='home-content' lg={12} md={12} sm={12}>
            <br />
            <h4>
              <strong>
                BECOME PART OF THE STORY
                <br />
                AND BEYOND.
              </strong>
            </h4>
            <hr />
            <p>
              Your Rebel is a piece of an ongoing story in the Board Ape
              Universe that's home to no less than 6 developing storylines,
              businesses, and IPs. As such, your Rebel and the thoughts of the
              community will help shape the future of the Board Ape Universe.
              <br />
              <br />
              Beyond that, your Rebel is also part of the Arq NFT Ecosystem. As
              a part of this ecosystem, the token functions as a permanent
              membership pass to any members only pricing, sale times, and
              additional features offered by our artist
            </p>
          </Col>
        </Row>
        <hr />
        <Row className='align-items-center' id='team'>
          <Col className='home-content' lg={8} md={8} sm={12}>
            <br />
            <h4>
              <strong>
                MEET THE
                <br /> TEAM.
              </strong>
            </h4>
            <hr />
            <p>
              The Boardwalk Resistance was created by me; Arq. The Rebels draw
              inspiration from a character a few friends and I came up with
              while playing one of the Jackbox Party Packs. After the Board Ape
              Collective's random popularity, the Boardwalk Resistance seemed
              like the natural continuation to the Board Ape storyline.
            </p>
            <p>
              <strong className='name'>ARQ</strong> Concept, Design,
              Development, All the words you're reading now
            </p>
            <a href='https://www.twitter.com/cryptarq'>
              <i className='fab fa-twitter'></i>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href='https://www.instagram.com/_cryptarq'>
              <i className='fab fa-instagram'></i>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href='https://arqhub.io'>
              <i className='far fa-globe'></i>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href='mailto:cryptarq@boardapecollective.com'>
              <i className='fas fa-envelope'></i>
            </a>
            <br />
            <br />
            <p className='text-small'>
              Check out some of my other work here:
              <br />
              <a href='https://opensea.io/collection/boardapecollective'>
                Board Ape Collective
              </a>
              &nbsp;|&nbsp;
              <a href='https://opensea.io/collection/arqskulls'>Arq Skulls</a>
              &nbsp;|&nbsp;
              <a href='https://opensea.io/collection/onlypens'>
                Only<strong>Pens</strong>
              </a>
              &nbsp;|&nbsp;
              <a href='https://opensea.io/collection/arqskullsorigins'>
                Arq Skulls - Origins
              </a>
              &nbsp;|&nbsp;
              <a href='https://opensea.io/collection/msfts'>MSFTS</a>
            </p>
          </Col>

          <Col className='home-content ' lg={4} md={4} sm={12}>
            <Row className='text-center jc-center'>
              <Image className='team-image' src={skull} />
            </Row>
          </Col>
        </Row>
        <hr />
        <Modal
          show={transferModalShow}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          keyboard={true}
          className='transfer-modal'
        >
          <Modal.Body>
            {renderSwitch(
              transactionStage,

              transactionHash
            )}

            <Form>
              <Button
                className='modal-button'
                style={{ color: "white" }}
                onClick={() => {
                  setTransferModalShow(!transferModalShow);
                  setTransactionStage("0");
                }}
              >
                <strong>CLOSE</strong>
              </Button>{" "}
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />
    </div>
  );
};

export default Home;
