import { useState } from "react";

export const useWallet = () => {
  const { ethereum } = window || undefined;
  const [currentAccount, setCurrentAccount] = useState<string | undefined>("");
  if (ethereum != undefined) {
    /*setCurrentAccount(ethereum.selectedAddress);*/
  }
  // @ts-ignore

  return { currentAccount, setCurrentAccount };
};
