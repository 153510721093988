import React from "react";

export const style = (
  <>
    <style>
      {`
                body {
                    background-color: #0e0e0e;
                }
                .buy-button {
                    background-color:  #1a1a1a;
                    color: white;
                }
                
                .buy-button:hover {
                    color:gray;
                }
                .fine--print{
                    font-size:small;
                }
                .accordion-button:hover {
                    background-color: #1a1a1a;
                    color:white;
                }
                
                .accordion-button:focus {
                    background-color: #d18421;
                    box-shadow: 0 0 0 .25rem rgba(255, 222, 23, 0.45);
                }
                
                .accordion-button:not(.collapsed) {
                    background-color: #d18421;
                    color: black;
                }
                
                .home-content{
                    background-color: #0e0e0e;
                    align-content:center;
                    flex-direction:column;
                }
                .input{
                    min-width:10% !important;
                }
                .hero-image {
                    width:100%;
                }
                .text-white{
                    color:white!important;
                }
                .padded-container{
                    padding-left:5%;
                    padding-right:5%;
                    margin-bottom: 300px;
                    color: white;
                }
                .image-grid{
                    display:flex;
                    flex-direction:row !important;
                    flex-wrap: wrap;
                }
                .grid-image{
                    width:50%;
                    padding:3%;
                    border-radius:10%;
                }.modal-button::after {
                    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='chevron-circle-down' class='svg-inline--fa fa-chevron-circle-down fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-207.5 86.6l115-115.1c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L256 303l-99.5-99.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l115 115.1c4.8 4.6 12.4 4.6 17.1-.1z'%3E%3C/path%3E%3C/svg%3E");
                  }
                  .modal-body{
                    text-align:center;
                    width:80%;
                  }
                  .modal-content{
                    align-items:center;
                    
                  }
                .small-text{
                    font-size:.75rem;
                    color:#cecece !important;
                }
                .align-items-top{
                    align-items:baseline;
                }
                .align-items-center{
                    align-items:center;
                }
                .mint-panel{
                    padding-left:5%;
                    padding-right:5%;
                    padding-top:10%;
                    padding-bottom:10%;
                    background-color:#d18421 !important;
                    background-color:#66c844;
                    color: black !important;
                }
                .countdown-timer{
                    font-size:2rem;
                }
                .center-padded{
                    padding-left:5%;
                    padding-right:5%;
                }
                .modal-backdrop.show{
                    opacity: .8;
                  }   
                  .modal-content{
                    background-color:#1b1b1b;
                    color:white;
                  }
                .modal-button{
                    width:200px;
                    background-color:#d18421 !important;
                    background-color:#66c844;
                    color: #1a1a1a !important;
                    border-color:#a55c0c;
                }
                .modal-button:hover{
                    width:200px;
                    background-color:#fff;
                    color: #1a1a1a !important;
                    border-color:#fff;
                }
                .modal-button:active{
                    width:200px;
                    background-color:#fff;
                    color: #1a1a1a !important;
                    border-color:#fff;
                }
                .jc-center{
                    justify-content:center;
                  }
                .team-image{
                    width:40%;
                }
                .modal-links:hover {
                    border-bottom: 4px solid #d18421;
                    transition: all 0.2s ease-out;
                    text-decoration: none;
                  }
                .mint-button{
                    background-color: #1a1a1a;
                    border-color:#1a1a1a;
                    color: #fff;
                    font-size: .75em;
                    height: 100%;
                    min-width: 3vw;
                }
                .mint-button:hover{
                    background-color: #fff;
                    color: #1a1a1a;
                    border-color: #fff;
                }
                .mint-button.active{
                    background-color: #fff;
                    color: #1a1a1a;
                    border-color: #fff;
                }
                .mint-button:focus{
                    color: #fff;
                    background-color: #1a1a1a;
                    border-color: #1a1a1a;
                    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 50%);
                    font-size: .75em;
                    height: 100%;
                    min-width: 5vw;
                }
                .home-content a:hover{
                    border-bottom: 4px solid #d18421;
                    transition: all 0.2s ease-out;
                }
                .name{
                    color:#d18421 !important;
                }
                .link-images{
                    height:40px;
                }
                .link-images-bg{
                    
                    height:50px;
                }
                .link-images:hover{
                    border-bottom: 4px solid #fff !important;
                    transition: all 0.2s ease-out;
                }
                .link-images-bg:hover{
                    border-bottom: 4px solid #fff !important;
                    transition: all 0.2s ease-out;
                }
                .link-a {
                    border-bottom: 0px solid #fff !important;
                }
                .ape-image{
                    border-radius: 3rem;
                }
            `}
    </style>
  </>
);
