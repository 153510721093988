import React, { useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { style } from "./Header.styles";
import logo from "../img/barlogo.png";

type Props = {
  currentAccount?: string;
  setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Header: React.FC<Props> = ({ currentAccount }) => {
  const { ethereum } = window;
  const [click, setClick] = useState(false);

  const [address, setAddress] = useState<string | undefined>(currentAccount);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = (link: string | undefined) => {
    setClick(false);
    if (link !== undefined) {
      window.location.href = link;
    } else {
      window.location.href = "";
    }
  };

  const connectWallet = async () => {
    if (!window.ethereum) {
      console.log("No wallet plugin is available!");

      return;
    }

    try {
      const [account] = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setAddress(account);
      return "";
    } catch (err) {
      console.log(err);
    }
  };
  connectWallet();
  return (
    <>
      {style}
      <Navbar className='navbar' bg='#1a1a1a'>
        <Container className='navbar-container'>
          <Navbar.Brand className='navbar-logo'>
            <a
              href='https://www.boardwalkresistance.com'
              onClick={() => {
                closeMobileMenu("https://www.boardwalkresistance.com");
              }}
            >
              <img alt='nav bar logo' className='navbar-logo-img' src={logo} />
            </a>
          </Navbar.Brand>
          <Nav className={click ? "nav-menu active" : "nav-menu"}>
            <Nav.Link
              className='nav-item nav-links'
              href='https://www.boardwalkresistance.com/#join'
              onClick={() => {
                closeMobileMenu("https://www.boardwalkresistance.com/#join");
              }}
            >
              Join the Resistance
            </Nav.Link>
            <Nav.Link
              className='nav-item nav-links'
              href='https://www.boardwalkresistance.com/#team'
              onClick={() => {
                closeMobileMenu("https://www.boardwalkresistance.com/#team");
              }}
            >
              Team
            </Nav.Link>
            <Nav.Link
              className='nav-item nav-links'
              href='https://www.boardwalkresistance.com/my-rebels/'
              onClick={() => {
                closeMobileMenu(
                  "https://www.boardwalkresistance.com/my-rebels/"
                );
              }}
            >
              My Rebels
            </Nav.Link>
            <Nav.Link
              className='nav-item nav-links'
              href='https://www.boardwalkresistance.com/provenance/'
              onClick={() => {
                closeMobileMenu(
                  "https://www.boardwalkresistance.com/provenance/"
                );
              }}
            >
              Provenance
            </Nav.Link>
            <Nav.Link
              className='nav-item nav-links'
              href='https://www.twitter.com/boardapeco'
              onClick={() => {
                closeMobileMenu("https://www.twitter.com/boardapeco");
              }}
            >
              <i className='fab fa-twitter'></i>
            </Nav.Link>
            <Nav.Link
              className='nav-item nav-links'
              href='https://discord.gg/AvQ3my7XkC'
              onClick={() => {
                closeMobileMenu("https://discord.gg/AvQ3my7XkC");
              }}
            >
              <i className='fab fa-discord'></i>
            </Nav.Link>
            {!address && (
              <Button
                className='nav-button connect-button'
                variant='flat'
                onClick={() => connectWallet()}
              >
                Connect to wallet
              </Button>
            )}
            {address && (
              <Navbar.Text className='nav-item nav-text'>
                <i className='far fa-wallet'></i>&nbsp; {address.slice(0, 5)}...
                {address.slice(address.length - 5)}&nbsp;
                <i className='fas fa-check'></i>
              </Navbar.Text>
            )}
          </Nav>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
