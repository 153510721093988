import React from "react";

export const style = (
  <>
    <style>
      {`
                a {
                    color: white;
                    text-decoration: none;
                }
                
                a:hover {
                    color: white;
                    
                }
                
                
                .navbar-text {
                    color: white;
                }
                .btn-rounded {
                    border-radius: 5px;
                }
                
                .connect-button {
                    background-color: white;
                    color: black;
                }
                .navbar {
                    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
                    height: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1rem;
                    position: sticky;
                    top: 0;
                    z-index: 999;
                  }
                  
                  .navbar-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 80px;
                    max-width: 1800px;
                  }
                  
                  .nav-button {
                    margin: 15px;
                    color: #1a1a1a;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 0.5rem 1rem;
                    
                    font-size: inherit;
                  }
                  .nav-button:hover {
                    transition: all 0.1s ease-out;
                    background-color: #;
                  }
                  .navbar-logo {
                    color: #fff;
                    transform: translate(1vw, 0%);
                    justify-self: start;
                    margin-left: 20px;
                    cursor: pointer;
                    text-decoration: none;
                   
                    display: flex;
                    align-items: center;
                  }
                  
                  .fa-typo3 {
                    margin-left: 0.5rem;
                    font-size: 1.8rem;
                  }
                  
                  .nav-menu {
                    display: grid;
                    grid-template-columns: repeat(7, auto);
                    grid-gap: 10px;
                    list-style: none;
                    text-align: center;
                    width: 75vw;
                    justify-content: end;
                    
                  }
                  
                  .nav-item {
                    height: 80px;
                  }
                  .nav-text {
                    color: white !important;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 0.5rem 1rem;
                    height: 100%;
                  }
                  .nav-item > a {
                    color: #fff;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 0.5rem 1rem;
                    height: 100%;
                  }
                  .nav-item > a {
                    color: #fff;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 0.5rem 1rem;
                    height: 100%;
                  }
                  
                  .nav-links {
                    color: #fff !important;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 0.5rem 1rem;
                    height: 100%;
                  }
                  
                  .nav-links:hover {
                    border-bottom: 4px solid #c1690a;
                    transition: all 0.2s ease-out;
                  }
                  
                  .fa-bars {
                    color: #fff;
                  }
                  
                  .nav-links-mobile {
                    display: none;
                  }
                  
                  .menu-icon {
                    display: none;
                  }
                  .navbar-logo-img {
                    height: 2em !important;
                  }
                  
                  @media screen and (max-width: 965px) {
                    .NavbarItems {
                      position: relative;
                    }
                    .navbar-logo-img {
                      height: 2em !important;
                    }
                    .navbar-container {
                      display: flex;
                      justify-content: center !important;
                      align-items: center;
                      height: 80px;
                      max-width: 1800px;
                    }
                    .nav-menu {
                      display: flex;
                      flex-direction: column !important;
                      width: 100%;
                      /*height: 90vh;*/
                      position: fixed;
                      top: 80px;
                      left: -100%;
                      opacity: 1;
                      transition: all 0.5s ease;
                    }
                  
                    .nav-menu.active {
                      background: #1a1a1a;
                      left: 0;
                      opacity: 1;
                      transition: all 0.5s ease;
                      z-index: 1;
                    }
                  
                    .nav-links {
                      text-align: center;
                      padding: 1rem;
                      width: 100%;
                      display: table;
                    }
                  
                    .nav-links:hover {
                      background-color: #fff;
                      color: #1a1a1a;
                      border-radius: 0;
                    }
                  
                    .navbar-logo {
                      /*position: absolute;*/
                      top: 0;
                      left: 0;
                      /*transform: translate(1vw, 15%);*/
                    }
                  
                    .menu-icon {
                      display: block;
                      position: absolute;
                      top: 0;
                      right: 0;
                      transform: translate(-100%, 60%);
                      font-size: 1.8rem;
                      cursor: pointer;
                    }
                  
                    .fa-times {
                      color: #fff;
                      font-size: 2rem;
                    }
                  
                    .nav-links-mobile {
                      display: block;
                      text-align: center;
                      margin: 2rem auto;
                      border-radius: 4px;
                      width: 80%;
                      text-decoration: none;
                      font-size: 1.5rem;
                      background-color: transparent;
                      color: #fff;
                      padding: 14px 20px;
                      border: 1px solid #fff;
                      transition: all 0.3s ease-out;
                    }
                  
                    .nav-links-mobile:hover {
                      background: #fff;
                      color: #1a1a1a;
                      transition: 250ms;
                    }
                  }
                  
            `}
    </style>
  </>
);
