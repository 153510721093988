import React from "react";

export const style = (
  <>
    <style>
      {`
                body {
                    background-color: #0e0e0e;
                }
                .enter-button{
                    width:200px;
                    background-color:#f1b302;
                    color: #1a1a1a;
                    border-color:#f1b302;
                }
                .enter-button:hover{
                    width:200px;
                    background-color:#fff;
                    color: #1a1a1a;
                    border-color:#fff;
                }
                .enter-button:active{
                    width:200px;
                    background-color:#fff;
                    color: #1a1a1a;
                    border-color:#fff;
                }
                .buy-button {
                    background-color:  #1a1a1a;
                    color: white;
                }
                
                .buy-button:hover {
                    color:gray;
                }
                
                .accordion-button:hover {
                    background-color: #1a1a1a;
                    color:white;
                }
                
                .accordion-button:focus {
                    background-color: #ffde17;
                    box-shadow: 0 0 0 .25rem rgba(255, 222, 23, 0.45);
                }
                
                .accordion-button:not(.collapsed) {
                    background-color: #ffde17;
                    color: black;
                }
                
                .home-content{
                    background-color: #0e0e0e;
                    align-content:center;
                    flex-direction:column;
                }
                
                .hero-image {
                    width:100%;
                }
                .text-white{
                    color:white!important;
                }
                .padded-container{
                    padding-left:10%;
                    padding-right:10%;
                    margin-bottom: 300px;
                    color: white;
                }
                
            `}
    </style>
  </>
);
