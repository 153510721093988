import "./Footer.css";
import skull from "../components/img/skull.svg";
import board from "./img/barlogo.png";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { style } from "./Fotter.styles";
function Footer() {
  return (
    <div className='footer-container'>
      <hr></hr>
      {style}
      <section className='footer-content'>
        <Row>
          <Col lg={4} className='footer-subsctiption '>
            <a
              className='footer-subsctiption text-small'
              href='https://www.twitter.com/boardapeco'
            >
              <i className='fab fa-twitter'></i>
            </a>
            <a
              className='footer-subsctiption text-small'
              href='https://discord.gg/AvQ3my7XkC'
            >
              <i className='fab fa-discord'></i>
            </a>
          </Col>
          <Col lg={4} className='text-center'>
            <img alt='footer logo' className='footer-image' src={board}></img>
          </Col>
          <Col lg={4} className='footer-subsctiption '>
            <a
              className='footer-subsctiption text-small'
              href='https://www.boardwalkresistance.com/terms/'
            >
              BAR Terms &amp; Conditions
            </a>
          </Col>
        </Row>
        <Row className='footer-subsctiption'>
          <p className='text-small'>
            <img alt='footer logo' className='footer-logo' src={skull} />
            &nbsp;&nbsp;&nbsp;©2023 Arq Media Solutions
            LLC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br />
          </p>
        </Row>
      </section>
    </div>
  );
}

export default Footer;
