import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./components/Home";
import Header from "./components/Header";
import { useWallet } from "./hooks/useWallet";
function App() {
  const { currentAccount, setCurrentAccount } = useWallet();
  //const { currentAccount, setCurrentAccount } = useWallet();
  const [address, setAddress] = useState<string>("");
  useEffect(() => {
    if (window.ethereum) {
      setAddress(window.ethereum.selectedAddress);
      // @ts-ignore
      window.ethereum.on("accountsChanged", ([newAccount]) => {
        setAddress(newAccount);
      });
    }
  }, []);
  return (
    <>
      <Header
        currentAccount={currentAccount}
        setCurrentAccount={setCurrentAccount}
      />
      <Home currentAccount={address} />
    </>
  );
}

export default App;
