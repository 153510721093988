import Web3 from "web3";

import abi from "../contracts/abi.json";
import erc721abi from "../contracts/rarible.json";
import { AbiItem } from "web3-utils";
import controlCenterAbi from "../contracts/ControlCenter.json";

let provider = window.ethereum;
let web3 = new Web3();

if (provider) {
  web3 = new Web3(provider);
} else {
  web3 = new Web3(
    new Web3.providers.HttpProvider(process.env.REACT_APP_TESTNET_API_URL!!)
  );
}

var contractAddress = process.env.REACT_APP_GOERLI_CONTRACT_ADDRESS;
var ccContractAddress = process.env.REACT_APP_GOERLI_CONTROL_CENTER;

var contract = new web3.eth.Contract(abi as AbiItem[], contractAddress);
var ccContract = new web3.eth.Contract(
  controlCenterAbi as AbiItem[],
  ccContractAddress
);
export const updateNetwork = (net: number) => {
  switch (net) {
    case 5:
      contractAddress = process.env.REACT_APP_GOERLI_CONTRACT_ADDRESS;
      ccContractAddress = process.env.REACT_APP_GOERLI_CONTROL_CENTER;
      contract = new web3.eth.Contract(abi as AbiItem[], contractAddress);
      ccContract = new web3.eth.Contract(
        controlCenterAbi as AbiItem[],
        ccContractAddress
      );
      break;
    case 3:
      contractAddress = process.env.REACT_APP_ROPSTEN_CONTRACT_ADDRESS;
      ccContractAddress = process.env.REACT_APP_ROPSTEN_CONTROL_CENTER;
      ccContract = new web3.eth.Contract(
        controlCenterAbi as AbiItem[],
        ccContractAddress
      );
      break;
  }
};
export const getTokenOwner = async (tokenContract: string, tokenID: string) => {
  const cont = new web3.eth.Contract(erc721abi as AbiItem[], tokenContract);
  return await cont.methods.ownerOf(tokenID).call();
};
export const getCCBalance = async (address: string) => {
  return await ccContract.methods.getBalance(address!!).call();
};
export const getSaleTime = async (address: string) => {
  return await contract.methods.getSaleTime(address).call();
};
export const getTimeUntil = async (address: string) => {
  return await contract.methods.getTimeUntilSale(address).call();
};
export const getCallerPrice = async (qty: number, ad: string) => {
  return await contract.methods.getCallerPrice(qty).call({ from: ad });
};
export const getEarlyAccessClaimed = async () => {
  return await contract.methods.getEarlyAccessClaimed().call();
};
export const getCurrentNetwork = async () => {
  return await web3.eth.net.getId();
};
export const getOwnedTokenBalance = async (address: string) => {
  const bal = await contract.methods.balanceOf(address).call();
  return bal;
};
export const getWalletApprovedForEA = async (address: string) => {
  const eaE = await contract.methods.walletApprovedForEA(address!!).call();
  return eaE;
};
export const getTokensOfOwner = async (address: string) => {
  const tok = await contract.methods.walletOfOwner(address!!).call();
  return tok;
};
export const getEarlyAccessBalance = async (address: string) => {
  const eaBal = await contract.methods.getEarlyAccessBalance(address!!).call();
  return eaBal;
};
export const fetchInEarlyAccess = async () => {
  const inEA = await contract.methods.inEarlyAccess().call();
  return inEA;
};
export const getTokenOfOwnerByIndex = async (
  address: string,
  index: number
) => {
  return await contract.methods.tokenOfOwnerByIndex(address, index).call();
};

export const getLoadedTokenURI = async (
  contractAddress: string,
  tokenId: number
) => {
  let targetContract = new web3.eth.Contract(
    erc721abi as AbiItem[],
    contractAddress
  );
  let uri = await targetContract.methods
    .tokenURI(tokenId)
    .call()
    .then((tx: string) => {
      return tx;
    });
  return uri;
};

export const getBalance = async (address: string) => {
  let balance = await web3.eth.getBalance(address);
  return web3.utils.fromWei(balance, "ether");
};

export const getTotalSupply = async () => {
  return await contract.methods.totalSupply().call();
};

export const getMaxSupply = async () => {
  return await contract.methods.max_rebels().call();
};

export const mintApe = async (qty: number) => {
  const { ethereum } = window;
  const callerPrice = await contract.methods
    .getCallerPrice(qty)
    .call({ from: ethereum.selectedAddress });
  try {
    const tx = await contract.methods
      .mint(qty)
      .send({ value: callerPrice, from: ethereum.selectedAddress });

    return tx;
  } catch (err) {
    console.log("Error:", err);
    return "Error:" + err;
  }
};
export const buyNFT = async () => {
  const { ethereum } = window;
  const transactionParams = {
    to: contractAddress,
    from: ethereum.selectedAddress,
    value: web3.utils.toHex(web3.utils.toWei("0.05", "ether")),
    gasLimit: web3.utils.toHex(300000),
    gasPrice: web3.utils.toHex(web3.utils.toWei("350", "gwei")),
    data: contract.methods.mint(1).encodeABI(),
  };
  return await ethereum.request({
    method: "eth_sendTransaction",
    params: [transactionParams],
  });
};

export const getToken = async (address: string) => {
  return await contract.methods.walletOfOwner(address).call();
};

export const getTokenUri = async (tokenId: number) => {
  console.log(contractAddress);
  return await contract.methods.tokenURI(tokenId).call();
};
