import { useEffect, useState } from "react";
import {
  getCallerPrice,
  getOwnedTokenBalance,
  getTimeUntil,
  getTotalSupply,
  getMaxSupply,
  getCurrentNetwork,
  getEarlyAccessBalance,
  fetchInEarlyAccess,
  getEarlyAccessClaimed,
  getWalletApprovedForEA,
  getCCBalance,
  updateNetwork,
} from "../network/ethereum";

export const useRebelContract = (address?: string) => {
  if (address) {
  }
  const [isLoading, setIsLoading] = useState(false);
  const [supply, setSupply] = useState<number>(0);
  const [mSupply, setMSupply] = useState<number>(0);
  const [uPrice, setUnitPrice] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [saleTime, setSaleTime] = useState<number>(0);
  const [network, setNetwork] = useState<number>(-1);
  const [earlyAccessBalance, setearlyAccessBalance] = useState<number>(0);
  const [mintStage, setmintStage] = useState<string>("0");
  const [earlyAccessClaimed, setEarlyAccessClaimed] = useState<number>(0);
  const [earlyAccessEligible, setEarlyAccessEligible] =
    useState<boolean>(false);
  const [controlCenterBalance, setControlCenterBalance] = useState<number>(0);
  useEffect(() => {
    const fetchOwnerBalance = async (address: string) => {
      try {
        try {
          getCurrentNetwork().then((net) => {
            setNetwork(net);
            updateNetwork(net);
          });
        } catch (e) {}

        let ts = 0;
        let bal = 0;
        let sup = 0;
        let mSup = 0;
        let unitPrice = 0;
        let eaAccess = 0;
        let mStage = "0";
        let eAClaimed = 0;
        let ea = false;
        let eaE = false;
        let ccB = 0;
        try {
          ccB = await getCCBalance(address!!);
        } catch (error: any) {
          console.log(error.message);
        }
        try {
          eaE = await getWalletApprovedForEA(address!!);
        } catch (e: any) {
          console.log(e.message);
        }
        try {
          ea = await fetchInEarlyAccess();
        } catch (e: any) {
          console.log(e.message);
        }
        try {
          eaAccess = await getEarlyAccessBalance(address!!);
        } catch (e: any) {
          console.log(e.message);
        }
        try {
          eAClaimed = await getEarlyAccessClaimed();
        } catch (error: any) {
          console.log(error.message);
        }
        try {
          ts = await getTimeUntil(address!!);
        } catch (e) {}
        try {
          bal = await getOwnedTokenBalance(address);
        } catch (e) {
          console.log("Error at Unit Price:", e);
          return;
        }
        try {
          sup = await getTotalSupply();
        } catch (e) {
          console.log("Error at Supply:", e);
        }
        try {
          unitPrice = await getCallerPrice(1, address);
        } catch (e) {
          console.log("Error at Unit Price:", e);
        }
        try {
          mSup = await getMaxSupply();
        } catch (e) {
          console.log("Error at Max Supply:", e);
        }
        console.log(ts);
        if (ts > 0 && !eaE) {
          if (3333 <= sup) {
            mStage = "3";
          } else {
            mStage = "0";
          }
        } else if (ts > 0 && earlyAccessBalance >= 5) {
          mStage = "0";
        } else if (ts > 0) {
          mStage = "0";
        } else {
          if (ea && eaE) {
            if (eAClaimed >= 1600) {
              mStage = "eaClaimed";
            } else {
              if (eaAccess >= 5) {
                mStage = "eaMinted";
              } else {
                mStage = "1";
              }
            }
          } else {
            if (mSup === sup) {
              mStage = "3";
            } else {
              if (bal - eaAccess >= 10) {
                mStage = "maxClaimed";
              } else {
                mStage = "2";
              }
            }
          }
        }
        console.log(mStage);
        setControlCenterBalance(ccB);
        setEarlyAccessClaimed(eAClaimed);
        setBalance(bal);
        setUnitPrice(unitPrice);
        console.log(unitPrice);
        setSupply(sup);
        setSaleTime(ts);
        setMSupply(mSup);
        setearlyAccessBalance(eaAccess);
        setEarlyAccessClaimed(eAClaimed);
        setmintStage(mStage);
        setEarlyAccessEligible(eaE);
        console.log(eaE);
      } catch (e) {
        console.log("Error fetching Time:", e);
      }
      setIsLoading(false);
    };
    if (address) {
      setIsLoading(true);
      fetchOwnerBalance(address!!);
    }
  }, [address]);
  return {
    isLoading,
    saleTime,
    supply,
    mSupply,
    uPrice,
    balance,
    network,
    earlyAccessBalance,
    mintStage,
    earlyAccessClaimed,
    earlyAccessEligible,
    controlCenterBalance,
  };
};
